<template>
  <div class="manual-qualified">
    <b-btn
      v-for="(setting, settingKey, setting_index) in values" :key="setting_index"
      variant="primary"
      :class="['button-outline-blue', select_value === setting.key ? 'router-link-active' : '']"
      @click="newSelect(setting.key)"
    >{{ setting.label }}</b-btn>
  </div>
</template>

<script>

import MtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  data() {
    return {
      select_value: '',
      values: [
        {
          key: 'AI Qualified',
          label: 'AI Qualified'
        },
        {
          key: 'Lead',
          label: 'Lead'
        },
        {
          key: 'Not Lead',
          label: 'Not Lead'
        }
      ]
    }
  },
  mounted() {
    this.select_value = this.params.value
  },
  methods: {
    async newSelect(value) {
      this.select_value = value
      const data = {
        event: 'update_contact',
        field: {
          name: 'manual_qualified',
          value
        }
      }
      await MtApi.updateContact(this.params.data._id, data)
    }
  }
}
</script>

<style lang="scss" scoped>
.manual-qualified {
  gap: 10px;
  display: flex;
  margin: 0 auto;
  button {
    border-radius: 0;
  }
}
</style>

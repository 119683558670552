<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="agGrid-page container">
      <base-header>
        <lead-center-date />
        <lead-center-contacts-management-addon />
      </base-header>
      <lead-center-charts-contacts v-if="showChartsLeadCenter && request" :request="request" />
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :statusBar="statusBar"
        :onSelectionChanged="onSelectionChanged"
        :masterDetail="true"
        :detailCellRendererParams="detailCellRendererParams"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
import LeadCenterChartsContacts from '@/agGridV2/components/lead-center-charts-contacts.component.vue'
import LeadCenterDate from '@/agGridV2/components/lead-center-date.component.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import LeadCenterCustomGroupRenderer from '@/agGridV2/renderers/lead-center-custom-group.renderer'
import LeadCenterManualQualifiedRenderer from '@/agGridV2/renderers/manual-qualified.renderer'
import LeadCenterNavigation from '@/components/LeadCenterNavigation.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import LeadCenterContactsManagementAddon from '@/agGridV2/components/header/lead-center-contacts-management.component.vue'
import StatusBar from '@/agGridV2/renderers/status-bar.renderer.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    LeadCenterDate,
    LeadCenterChartsContacts,
    LeadCenterContactsManagementAddon,
    BaseHeader,
    LeadCenterNavigation,
    BaseAgGrid,
    // eslint-disable-next-line vue/no-unused-components
    StatusBar,
    // eslint-disable-next-line vue/no-unused-components
    LeadCenterCustomGroupRenderer,
    // eslint-disable-next-line vue/no-unused-components
    LeadCenterManualQualifiedRenderer
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      customHtml: null,
      onSelectionChanged: null,
      statusBar: null,
      detailCellRendererParams: null,
      request: null,
      gridApi: null
    }
  },
  computed: {
    showChartsLeadCenter() {
      return this.getShowChartsLeadCenter()
    }
  },
  methods: {
    ...mapGetters([
      'getShowChartsLeadCenter'
    ]),
  },
  async mounted() {
    this.$emitter.on('header_addons_changed', () => {
      if (this.gridApi && this.gridApi.destroyCalled === false) {
        this.gridApi.refreshServerSide({ purge: true })
      }
    })

    const filters = await MtApi.getContactsFilters({
      platform_id: this.$store.getters.getSelectedPlatform._id,
      fields: ['type', 'source', 'medium', 'service', 'location', 'ai_qualified', 'manual_qualified', 'channel']
    })

    this.statusBar = {
      statusPanels: [
        {
          statusPanel: 'StatusBar'
        }
      ]
    }
    this.columnDefs = [
      {
        field: 'activities_count',
        headerName: '',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'LeadCenterCustomGroupRenderer',
        width: 70
      },
      {
        field: '_id',
        headerName: 'ID',
        filter: false
      },
      {
        field: 'created_date',
        headerName: 'Created Date'
      },
      {
        field: 'updated_date',
        headerName: 'Updated Date'
      },
      {
        field: 'type',
        headerName: 'Type',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters.type))
          }
        }
      },
      {
        field: 'name',
        headerName: 'Name'
      },
      {
        field: 'phone',
        headerName: 'Phone'
      },
      {
        field: 'email',
        headerName: 'Email'
      },
      {
        field: 'message',
        headerName: 'Message',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        editable: true
      },
      {
        field: 'channel',
        headerName: 'Channel',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters.channel))
          }
        }
      },
      {
        field: 'source',
        headerName: 'Source',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters.source))
          }
        }
      },
      {
        field: 'medium',
        headerName: 'Medium',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters.medium))
          }
        }
      },
      {
        field: 'service',
        headerName: 'Service',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters.service))
          }
        }
      },
      {
        field: 'location',
        headerName: 'Location',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters.locations))
          }
        }
      },
      {
        field: 'ai_qualified',
        headerName: 'AI Qualified',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters.ai_qualified))
          }
        }
      },
      {
        field: 'manual_qualified',
        headerName: 'Manual Requalification',
        cellRenderer: 'LeadCenterManualQualifiedRenderer',
        filter: 'agSetColumnFilter',
        width: '300',
        filterParams: {
          values: (params) => {
            params.success(SimpleHelper.getModifiedFiltersLabels(filters.manual_qualified))
          }
        }
      }
    ]
    this.detailCellRendererParams = (masterGridParams) => ({
      detailGridOptions: {
        headerHeight: 32,
        rowHeight: 32,
        columnDefs: [
          {
            field: '_id',
            headerName: 'ID',
            filter: false
          },
          {
            field: 'created_date',
            headerName: 'Created Date'
          },
          {
            field: 'type',
            headerName: 'Type'
          },
          {
            field: 'tech_data.application',
            headerName: 'Application'
          },
          {
            field: 'automate_data.name',
            headerName: 'Name'
          },
          {
            field: 'automate_data.phone',
            headerName: 'Phone'
          },
          {
            field: 'automate_data.email',
            headerName: 'Email'
          },
          {
            field: 'automate_data.message',
            headerName: 'Message',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
              maxLength: 100,
              rows: 10,
              cols: 50
            },
            editable: true
          },
          {
            field: 'automate_data.address',
            headerName: 'Address'
          },
          {
            field: 'automate_data.location',
            headerName: 'Location'
          },
          {
            field: 'automate_data.service',
            headerName: 'Service'
          },
          {
            field: 'automate_data.landing_page',
            headerName: 'Landing Page',
            cellRenderer: (params) => {
              const value = params.getValue()
              if (value) {
                return `<a href="${value}" target="_blank">${value}</a>`
              }
            }
          },
          {
            field: 'automate_data.source',
            headerName: 'Source'
          },
          {
            field: 'automate_data.medium',
            headerName: 'Medium'
          },
          {
            field: 'status',
            headerName: 'Technical Status'
          },
          {
            field: 'automate_data.ai_qualified',
            headerName: 'AI Qualified',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
              maxLength: 100,
              rows: 10,
              cols: 50
            },
            editable: true
          },
          {
            field: 'raw_data',
            headerName: 'Raw Data',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
              maxLength: 100,
              rows: 10,
              cols: 50
            },
            editable: true,
            valueGetter: (params) => {
              return JSON.stringify(params.data.raw_data, null, 2)
            },
            filter: null
          },
          {
            field: 'tech_data',
            headerName: 'Tech Data',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
              maxLength: 100,
              rows: 10,
              cols: 50
            },
            editable: true,
            valueGetter: (params) => {
              return JSON.stringify(params.data.tech_data, null, 2)
            },
            filter: null
          },
          {
            field: 'cookie_data',
            headerName: 'Cookie Data',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
              maxLength: 100,
              rows: 10,
              cols: 50
            },
            editable: true,
            valueGetter: (params) => {
              return JSON.stringify(params.data.cookie_data, null, 2)
            },
            filter: null
          },
          {
            field: 'automate_data',
            headerName: 'Automate Data',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
              maxLength: 100,
              rows: 10,
              cols: 50
            },
            editable: true,
            valueGetter: (params) => {
              return JSON.stringify(params.data.automate_data, null, 2)
            },
            filter: null
          },
          {
            field: 'logs',
            headerName: 'Logs',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
              maxLength: 100,
              rows: 10,
              cols: 50
            },
            valueGetter: (params) => {
              return params.data.logs.join('\n\n')
            },
            editable: true,
            filter: null
          }
        ]
      },
      // get the rows for each Detail Grid
      getDetailRowData: (params) => {
        (async () => {
          params.successCallback(await MtApi.getContactActivities(params.data._id))
        })()
      }
    })
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        params.request['platform'] = { _id: this.$store.getters.getSelectedPlatform._id }
        if (this.$store.getters.getLeadCenterDate?.start?.code) {
          params.request['startDate'] = this.$store.getters.getLeadCenterDate.start.code
        }
        if (this.$store.getters.getLeadCenterDate?.end?.code) {
          params.request['endDate'] = this.$store.getters.getLeadCenterDate.end.code
        }
        this.request = params.request
        this.gridApi = params.api
        MtApi.agGridContacts(params.request).then((response) => {
          this.$emitter.emit('status_bar_update', {
            count: response.count
          })
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
  }
}
</script>
